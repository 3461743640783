import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorInterceptor} from './shared/_helpers/error.interceptor';
import {JwtInterceptor} from './shared/_helpers/jwt.interceptor';
// Module
// Router
import {appRouter} from './app.router';
// Guard
import {AuthGuard} from './shared/_guards/auth.guard';
// Component
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {NotfoundComponent} from './shared/components/notfound/notfound.component';
import {ShareModule} from './shared/shareModule/share.module';

@NgModule({
    declarations: [
        AppComponent,
        NotfoundComponent,
    ],
    imports:
        [
            CommonModule,
            BrowserModule,
            FormsModule,
            HttpClientModule,
            // add new Module
            ShareModule,
            RouterModule.forRoot(appRouter),

        ],
    providers: [
        AuthGuard,

        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    ],
    bootstrap: [AppComponent],

})
export class AppModule {
}

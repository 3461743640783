import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-notfound-component',
    templateUrl: 'notfound.component.html'
})

export class NotfoundComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {

    }
}
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingComponent} from '../_directives/loading/loading.component';
import {AuthService} from '../_service/auth.service';
import {ShareService} from './share.service';


@NgModule({
    imports: [
        CommonModule,

    ],
    declarations: [
        LoadingComponent,

    ],
    exports: [
        LoadingComponent
    ],
    providers: [
        AuthService,
    ]
})
export class ShareModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShareModule,
            providers: [ShareService]
        };
    }
}
